import { Provider } from "@xiag/container";
import { NullLogger } from "src/Modules/Logger/NullLogger";
import { ConsoleLogger } from "src/Modules/Logger/ConsoleLogger";
import { LoggerFactory } from "src/Modules/Logger/LoggerFactory";

export const LOGGER_PROVIDER = new Provider([])
    .constant(
        "$logDebug",
        true,
    )
    .constant(
        "$logEnable",
        true,
    )

    .service(
        "$loggerNull",
        NullLogger,
        NullLogger.$inject || [],
    )
    .service(
        "$loggerConsole",
        ConsoleLogger,
        ConsoleLogger.$inject || [],
    )

    .factory(
        "$logger",
        LoggerFactory,
        LoggerFactory.$inject || [],
    );
