import { Provider } from "@xiag/container";
import { createHashHistory, HashHistoryBuildOptions } from "history";

export const BROWSER_PROVIDER = new Provider([])
    .constant<HashHistoryBuildOptions>("$historyOptions", {
        basename: "/",
        hashType: "hashbang",
    })
    .factory(
        "$history",
        createHashHistory,
        ["$historyOptions"],
    )
    .constant(
        "$window",
        window,
    )
    .constant(
        "$location",
        location,
    )
    .constant(
        "$queryString",
        new URLSearchParams(location.search),
    )
    .constant(
        "$document",
        document,
    );
