import { MapOf } from "src/Utils/MapOf";
import { LogicError } from "src/Errors/LogicError";
import { ILogger } from "src/Modules/Logger/Logger";
import { LogLevel } from "src/Modules/Logger/LogLevel";

export class ConsoleLogger implements ILogger {
    private logMap: MapOf<Function> = {
        // tslint:disable-next-line:no-console
        [LogLevel.DEBUG]: (...args: any[]) => console.debug(...args),
        // tslint:disable-next-line:no-console
        [LogLevel.INFO]: (...args: any[]) => console.info(...args),
        // tslint:disable-next-line:no-console
        [LogLevel.NOTICE]: (...args: any[]) => console.log(...args),
        // tslint:disable-next-line:no-console
        [LogLevel.WARNING]: (...args: any[]) => console.warn(...args),
        // tslint:disable-next-line:no-console
        [LogLevel.ERROR]: (...args: any[]) => console.error(...args),
    };

    public static $inject: string[] = [
        "$logDebug",
    ];
    public constructor(
        $logDebug: boolean,
    ) {
        if (!$logDebug) {
            this.logMap = {
                ...this.logMap,
                [LogLevel.DEBUG]: new Function(),
            };
        }
    }

    public debug(message: string, context?: any): void {
        return this.log(LogLevel.DEBUG, message, context);
    }
    public info(message: string, context?: any): void {
        return this.log(LogLevel.INFO, message, context);
    }
    public notice(message: string, context?: any): void {
        return this.log(LogLevel.NOTICE, message, context);
    }
    public warning(message: string, context?: any): void {
        return this.log(LogLevel.WARNING, message, context);
    }
    public error(message: string, context?: any): void {
        return this.log(LogLevel.ERROR, message, context);
    }

    public log(level: LogLevel, message: string, context?: any): void {
        if (!this.logMap[level]) {
            throw new LogicError(`Invalid log level "${level}"`, {level, message, context});
        }

        const args = context === undefined ? [message] : [message, context];
        this.logMap[level](...args);
    }
}
