import { ILogger } from "src/Modules/Logger/Logger";

export class NullLogger implements ILogger {
    public debug(): void { /* noop */ }
    public info(): void { /* noop */ }
    public notice(): void { /* noop */ }
    public warning(): void { /* noop */ }
    public error(): void { /* noop */ }
    public log(): void { /* noop */ }
}
