export enum EndpointName {
    FILE = "file",

    AGENDA_CONFIG = "agenda/config",
    CHECKLIST_CONFIG = "checklist/configuration",

    CORE_APP = "core/app",
    CORE_CONFIG = "core/config",
    CORE_EXTERNAL_LINK = "core/external-link",
    CORE_MAPPING = "core/mapping",
    CORE_MODULE = "core/module",
    CORE_SCREEN = "core/screen",
    CORE_TEXT_BLOCK = "core/textblock",
    CORE_VERSION = "core/version",

    ENTITY_CODE = "entity/code",
    ENTITY_CONTRACT = "entity/contract",

    ACCOUNT_GROUP = "account/group",
    ACCOUNT_ACCOUNT = "account/account",
    ACCOUNT_POSITION = "account/position",

    BALANCE_CATEGORY = "balance/category",
    BALANCE_CATEGORY_MAPPING = "balance/categorymapping",
    BALANCE_PRODUCT_ADDITION = "balance/productaddition",

    BASIC_PRODUCT = "basic/product",
    BASIC_PRODUCT_CATEGORY = "basic/productcategory",
    BASIC_PRODUCT_DOCUMENT = "basic/productdocument",
    BASIC_PRODUCT_ADDITION = "basic/productaddition",
    BASIC_INTERMEDIATE_VIEW = "basic/intermediateview",

    SERVICE_CARD = "service/card",
    SERVICE_ESERVICE = "service/eservice",
    SERVICE_LSV = "service/lsv",
    SERVICE_STANDINGORDER = "service/standingorder",
    SERVICE_SETTLEMENT_COSTS = "service/settlementcosts",

    PERSON_WHOAMI = "person/whoami",
    PERSON_CUSTOMER = "person/customer",
    PERSON_CUSTOMER_BASICPRODUCT = "customer/basicproduct",
    PERSON_CUSTOMER_ASSIGNMENT = "customer/customerassignment",
    PERSON_CUSTOMER_BALANCE = "person/customer-balance",
    PERSON_CUSTOMER_GROUP = "person/customer-group",
    PERSON_CUSTOMER_INCOME = "person/customer-income",
    PERSON_CONSULTANT = "person/consultant",

    PERSISTENCE_FAVOURITE_DOCUMENTS = "consultation/persistence/favouritedocuments",
    PERSISTENCE_FAVOURITE_INSTRUMENTS = "consultation/persistence/favouriteinstruments",
    PERSISTENCE_REQUISITION_ITEM = "consultation/persistence/requisition-item",
    PERSISTENCE_RISK_TOLERANCE = "consultation/persistence/risktolerance",
    PERSISTENCE_WISH_RESERVE = "consultation/persistence/wishreserve",

    CONSULTATION = "consultation",

    INVESTMENT_ASSET_ALLOCATION = "investment/assetallocation",
    INVESTMENT_ASSET_CLASS = "investment/assetclass",
    INVESTMENT_CUSTOMER_RISK_PROFILE = "investment/customerriskprofile",
    INVESTMENT_DOCUMENT = "investment/document",
    INVESTMENT_DURATION = "investment/duration",
    INVESTMENT_FINANCING = "investment/financing",
    INVESTMENT_FX_RATE = "investment/fxrates",
    INVESTMENT_PRODUCT = "investment/product",
    INVESTMENT_PROFILE = "investment/profile",
    INVESTMENT_QUESTIONNAIRE = "investment/questionnaire",
    INVESTMENT_RANGE = "investment/range",
    INVESTMENT_RECOMMENDATION = "investment/recommendation",
    INVESTMENT_SERVICE_CLASS_RESTRICTION = "investment/serviceclassrestriction",
    INVESTMENT_SERVICE_SUB_TYPE = "investment/servicesubtype",
    INVESTMENT_SERVICE_TYPE = "investment/servicetype",
    INVESTMENT_STRATEGY = "investment/strategy",
    INVESTMENT_STRATEGY_ASSIGNMENT = "investment/strategyassignment",
    INVESTMENT_STRATEGY_RETURN = "investment/strategyreturn",
    INVESTMENT_VALOR = "investment/valor",

    FINANCING_AFFORDABILITY = "financing/affordability",
    FINANCING_AMORTIZATION = "financing/amortization",
    FINANCING_BLOCK_MODULE = "financing/blockmodule",
    FINANCING_CASH_FLOW_TYPE = "financing/cashflowtype",
    FINANCING_COLLATERAL = "financing/collateral",
    FINANCING_COLLATERAL_TYPE = "financing/collateraltype",
    FINANCING_DURATION = "financing/duration",
    FINANCING_EQUITY_TYPE = "financing/equitytype",
    FINANCING_FORWARD = "financing/forward",
    FINANCING_HOLIDAY = "financing/holiday",
    FINANCING_LIBOR = "financing/libor",
    FINANCING_LOAN_PRODUCT = "financing/loanproduct",
    FINANCING_LOAN_PRODUCT_GROUP = "financing/loanproductgroup",
    FINANCING_OBJECT = "financing/object",
    FINANCING_RATE = "financing/rate",
    FINANCING_REAL_ESTATE_TYPE = "financing/realestatetype",
    FINANCING_REFI_INTEREST_RATE = "financing/refi-interest-rate",
    FINANCING_SURCHARGE_USE_TYPE = "financing/surcharge-usetype",
    FINANCING_SURCHARGE_LENDING_RATE = "financing/surcharge-lendingrate",
    FINANCING_TRANCHE = "financing/tranche",

    EVENT_ACTION = "event/action",
    EVENT_STATUS = "event/status",
    EVENT_WORKER = "event/worker",

    WORK_CUSTOMER_NOTE = "work/customer-note",
    WORK_CUSTOMER_TASK = "work/customer-task",

    PRINT_DOCUMENT = "print/document",
    PRINT_INVESTMENT_DOCUMENT = "print/investment-document",

    REQUISITION_GROUP = "requisition/groups",
}
