import axios from "axios";
import { Provider } from "@xiag/container";
import { HttpFactory } from "src/Modules/Http/HttpFactory";

export const HTTP_PROVIDER = new Provider()
    .constant(
        "$httpBackend",
        axios,
    )
    .factory(
        "$http",
        HttpFactory,
        HttpFactory.$inject || [],
    );
