import { AxiosInstance, AxiosStatic } from "axios";
import { ApplicationState } from "src/State/ApplicationState";

HttpFactory.$inject = [
    "$state",
    "$httpBackend",
];
export function HttpFactory(
    $state: ApplicationState,
    $httpBackend: AxiosStatic,
): AxiosInstance {
    return $httpBackend.create({
        baseURL: $state.parameters.grvURL.replace(/\/+$/, "") + "/",
    });
}
