import { ILogger } from "src/Modules/Logger/Logger";

LoggerFactory.$inject = [
    "$logEnable",
    "$loggerNull",
    "$loggerConsole",
];
export function LoggerFactory(
    $logEnable: boolean,
    $loggerNull: ILogger,
    $loggerConsole: ILogger,
): ILogger {
    return $logEnable ? $loggerConsole : $loggerNull;
}
